@font-face {
  font-family: 'LibreFranklinItalic';
  src: local('LibreFranklinItalic'), url(./fonts/LibreFranklin-Italic-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'LibreFranklin';
  src: local('LibreFranklin'), url(./fonts/LibreFranklin-VariableFont_wght.ttf) format('truetype');
}


* {
  outline: none !important;
   box-shadow: none !important;
}



body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: 'LibreFranklin', sans-serif !important;
}

a:hover{text-decoration: none !important;}
.loader{ z-index:10; position:fixed; top:0; left:0; width: 100%; height: 100%; pointer-events: none;  opacity: 0; transition: opacity 1s ;   background-color:rgba(0, 38, 100, 0);}
.loader.loading{  opacity: 1; transition-delay: 0.5s;  }
.loader.loaded{  }


.slide-enter {
    position: absolute; top:0; left:0; width:100%; z-index: 5;
  transform: translateX(100%);
}
.slide-enter-active {
    position: absolute; top:0; left:0; width:100%;  z-index: 5;
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slide-exit{
    position: absolute; top:0; left:0; width:100%;
  transform: translateX(0%);
}
.slide-exit-active {
    position: absolute; top:0; left:0; width:100%;  z-index: 5;
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out;
}

.reverse-animation .slide-enter {
    position: absolute; top:0; left:0; width:100%; z-index: 5;
  transform: translateX(-100%);
}
.reverse-animation .slide-enter-active {
    position: absolute; top:0; left:0; width:100%;  z-index: 5;
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.reverse-animation .slide-exit{
    position: absolute; top:0; left:0; width:100%;
  transform: translateX(0%);
}
.reverse-animation .slide-exit-active {
    position: absolute; top:0; left:0; width:100%;  z-index: 5;
  transform: translateX(100%);
  transition: transform 500ms ease-in-out;
}


.loading .slide-side-menu-enter {
}
.loading .slide-side-menu-enter-active {
  transition-delay: 99999s;
}
.loading  .slide-side-menu-exit{
}
.loading  .slide-side-menu-exit-active {
  transition-delay: 99999s;
}

.background-grey {background-color:#f3f3f3;}
.background-grey .close-fixed-bottom{background-color:#f3f3f3;}
.background-grey .close-fixed-top{background-color:#f3f3f3;}

.slide-side-menu-enter {
    position: absolute; top:0; left:0; width:100%; z-index: 1;
  transform: translateX(-100%);
}
.slide-side-menu-enter-active {
    position: absolute; top:0; left:0; width:100%;  z-index: 1;
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;
}
.slide-side-menu-exit{
    position: absolute; top:0; left:0; width:100%;
  transform: translateX(0%);
}
.slide-side-menu-exit-active {
    position: absolute; top:0; left:0; width:100%;
  transform: translateX(-100%);
  transition: transform 250ms ease-in-out;
}

.opacity-side-menu-enter {
  opacity:0;
}
.opacity-side-menu-enter-active {
  transition: opacity 150ms ease-in-out;
    opacity:1;

}
.opacity-side-menu-exit{
  opacity:1;
}
.opacity-side-menu-exit-active {
  opacity:0;
  transition: opacity 150ms ease-in-out;
}

.titolo {color: #002664; font-weight: bold; font-size:22px; text-transform: uppercase; text-align: center; margin-bottom: 30px;}

.main-sep {height: 20px;}
.form-control,.btn{border-radius:0!important; }
.btn { width:100%; background-color: #E6E6E6 !important; margin-top:6px; margin-bottom: 3px; transition: background-color 0.5s; text-transform: uppercase;}
.btn.active,  .btn:hover { background-color: #cccccc !important;}
.btn-white { display:block;  text-align: center; color:black !important; text-decoration: none;  width:100%; background-color: white !important; margin-top:6px; margin-bottom: 3px; border:#cccccc 2px solid; transition: background-color 0.5s; }
.btn-white.active,  .btn-white:hover { background-color: #cccccc !important;}
.btn-blue {background-color: #002664 !important; color:white !important;}
.btn-blue.active, .btn-blue:hover {background-color: #003399 !important;}
.btn-back {background-color: #cacaca !important;  transition: none !important;}
.btn-back:hover {background-color: #9e9e9e !important;}

.react-loading-skeleton {height: 20px; margin-top:10px;}

.m-f{margin-bottom:75px;}
.p-f{padding-bottom:75px;}
.h-f{height:75px;}

.aligncenter {display: block; margin-left: auto; margin-right: auto;}

.topbar {position:fixed; width:100%; left:0; top:0; background-color: #002664; height:80px;  z-index: 4;}
.topbar {overflow: hidden;}
.topbar .header-logo {height: 80px; overflow-x: hidden;width: 100vw;}
.topbar .header-logo img{height:60px; margin-top:7px;}
.topbar .hamburger-container {position:absolute; top: 13px;}



body.sidemenu-open{overflow: hidden; height:100vh;}
.sidemenu{position: fixed; top:80px; overflow-y: scroll; z-index: 6;}
.sidemenu .background {position:fixed; top:80px; left:0; bottom:0; right: 0; background-color:rgba(0, 38, 100, 0.1);   }
.sidemenu .menu{ position:fixed;overflow-y: scroll;  top:80px; bottom:0; background-color:rgba(0, 38, 100, 1); width: 40vw; min-width: 400px;   padding:15px;}
.sidemenu .menu .sidemenu-search {height:70px; }
.sidemenu .menu .sidemenu-search {margin-top:20px;}
.sidemenu .menu .sidemenu-search .search-icon {width:20px;}
.sidemenu .menu .sidemenu-search .btn {margin:0 !important;}
.sidemenu .menu .sidemenu-item {height:80px; color:white; cursor: pointer; border-bottom: white solid 1px; transition: color 0.5s;}
.sidemenu .menu .sidemenu-item:hover {color:#cccccc;}
.sidemenu .sidemenu-search .btn{height: 100%; border: transparent 0 solid;}
.sidemenu .sidemenu-search input{height: auto; border: 1px solid white;background-clip: content-box;}
.sidemenu .sidemenu-search-box {background-color: white;}
.technical-data .search-box .btn{height: 100%; }
.technical-data .search-box input{height: auto;      }

@media only screen and (max-width: 600px) {
  .sidemenu .menu {width:100%; min-width: 100%;}
}



.languages {background-color:#002664; height: 100vh !important; margin-top:0 !important; position:relative; z-index:5 !important; }
.languages .trelleborg-logo-container{ max-width: 600px;  margin:5vh auto; }
.languages .tirebook-logo-container{ max-width: 600px;  margin:3vh auto; }

.languages .card {background-color:transparent;  border-radius: 0; border: none ;}
.languages .card-header{background-color:transparent;  border: none ;}
.languages .languages-button {cursor: pointer; max-width: 600px; margin:3vh auto 0 auto; text-align: center; text-transform: uppercase; color:white; border: white 1px solid ; padding:10px 0;}
.languages .language-button { text-align: center; padding:5px 0; font-size:18px;}
.languages .language-button a {color:white !important;}

.breadcrumb-section {background-color: #E6E6E6 !important; text-transform:uppercase; font-size: 14px; padding: 3px 0;}
.breadcrumb-section .goto-index {color:inherit;  margin-left: 15px;}
.breadcrumb-section .icona {font-size:14px;}

.descrizione {text-align:center;}
.main{height: calc(100vh - 80px); margin-top:80px; overflow-y: scroll;}
.main .descrizione{margin-top:30px;}
.descrizione img {max-width: 100%; height: auto;}
.main .main-menu {margin-top:30px;}

@media (max-width: 768px)
{
    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
}
@media (max-width: 1199px)
{
    .container, .container-md, .container-sm {
        max-width: 100% !important;
    }
    
}
.product-range .nav {margin-left: -8px; margin-right: -8px;}
.product-range .nav-tabs {border: 0;}
.product-range .nav-link { border: 3px #E6E6E6  solid !important;  border-color:#E6E6E6 !important;}
.product-range .nav-link.active {}

.product-range .nav-item {  transition: background-color 0.5s;   flex-basis: 0; flex-grow: 1;  margin:8px; border-radius: 0 !important; 
                            padding-top:3px; padding-bottom: 3px; background-color: #E6E6E6 !important; text-align: center; text-transform: uppercase;
                            color:black !important; }
.product-range .nav-item:hover,.product-range .nav-item.active  { background-color: white !important;  }
.product-range .tab-content {margin-top:15px; margin-bottom: 15px;}
.product-range .btn {min-height: 62px;}

.product-list a{text-decoration: none !important; color:#002664 !important; }
.product-list  .product-link {margin-top:3px; margin-bottom: 3px; font-size:20px; text-align:center;}
.product-list  .product-link.active { background-color: #eeeeee;}

.product .titolo{margin-bottom: 20px;}
.product .sotto-immagine {background-color: #E6E6E6 !important; text-align: center; border: black 1px solid; }
.product .box-categoria-prodotto {margin-top:15px; margin-bottom:15px;}
.product .categoria-prodotto {width: 100%;  display:block;  text-align: center; color:black !important; text-decoration: none;  }
.product .size-titolo{width:100%; background-color:#E6E6E6;text-align: center; padding: 5px; margin-top:14px; }

.size-list {margin-bottom: 80px;}
.size-list .size-link { text-align: center; margin-top:10px;}
.size-list .size-link.active { background-color: #eeeeee;}
.size-list .size-link a{color:black !important; text-decoration: none;}
.size-list .no-result {text-align: center; margin-top:10px;}

.size-sheet-container {width:fit-content;height:fit-content; background-color: white; padding: 20px; min-height: calc(100vh - 200px); cursor:context-menu !important;  padding-bottom:60px;    }
.size-error{padding-top:80px; text-align: center;  height: calc(100vh - 170px);}
.size-col {height: calc(100vh - 130px );}



.close-fixed-bottom {position:fixed; left:0; right:0; bottom: 0; padding-bottom: 3px; background-color: white;}
.close-fixed-top{position:fixed; left:0; right:0; top: 80px; padding-top: 0;padding-bottom: 3px; background-color: white; z-index: 3;}


.technical-data .search-box {margin-top:15px; }
.technical-data .btn {margin:0; border: #ced4da 1px solid;}
.technical-data .search-icon {
    width: 20px;
}
.technical-data .result{ text-align: center;}
.technical-data .react-loading-skeleton {margin-top:15px; display: block; margin-left:auto;margin-right:auto;}

.technical-data .symbol .btn {font-size:11px;}


.technical-data .symbol { margin-left: auto; margin-right: auto;   width:fit-content;  }
.technical-data-symbols .content {margin-bottom:60px;}

.technical-informations-page .ti-container {padding-bottom: 60px; text-align: center;}
.technical-informations-page  .pinch-container {margin-top:32px;}

.error-connection {text-align: center; margin-top: 150px; margin-bottom:100px;}
.error-connection h2 { margin-bottom: 40px;}
.error-connection .btn { margin-top: 60px;}

.lista-grigia {background-color: #efefef; margin-top:15px; padding:15px;  height: 100px; font-weight: bold; font-size:20px; color:#002664 !important; cursor:pointer;}
.lista-grigia a {color:#002664 !important;  }
.lista-grigia .icona {margin-right: 30px; height: 100%; width:auto;}
@media (max-width: 576px)
{
    .lista-grigia { height: 60px; font-weight: bold; font-size:16px; color:#002664 !important; cursor:pointer;}
    .lista-grigia .icona {margin-right: 15px; }
}
.modal-dialog {margin-top:80px !important; }

.lista-social {background-color: #efefef;  padding:15px;  min-height: 80px; text-align: center; margin-top:15px;}
.lista-social .icona {margin:15px 30px; 
    width: 80px;
    height: 80px;
    border: #787878 3px solid;
    padding: 15px;
}


.size-filter  .filter
{margin-bottom: 10px;}



.tire-size-corrispondence .size-params-container,
.ccalculator .size-params-container
{
    margin-top: 20px;
    
}
.tire-size-corrispondence .size-params-container,
.tire-size-corrispondence-result .size-params-container,
.ccalculator .size-params-container,
.ccalculator-result .size-params-container
{
    margin-bottom: 20px;
}

.tire-size-corrispondence .size-params-row,
.tire-size-corrispondence-result .size-params-row,
.ccalculator .size-params-row,
.ccalculator-result .size-params-row
{
    margin-left:0;
    margin-right:0;
    
}
.tire-size-corrispondence .symbol .btn {font-size:11px;}

.tire-size-corrispondence .symbol { margin-left: auto; margin-right: auto;   width:fit-content;  }

.tire-size-corrispondence .size-params-titolo,
.tire-size-corrispondence-result .size-params-titolo,
.ccalculator .size-params-titolo,
.ccalculator-result .size-params-titolo
{
    text-align: center;
    margin-bottom: 10px; font-weight: bolder; color:#002664;
}
.tire-size-corrispondence .size-params-row:nth-child(2n),
.tire-size-corrispondence-result .size-params-row:nth-child(2n),
.ccalculator .size-params-row:nth-child(2n),
.ccalculator-result .size-params-row:nth-child(2n)
{
    background-color:#eeeeee ;
}
.tire-size-corrispondence .btn-container {margin-top:15px;}
.tire-size-corrispondence .btn-container.disabled,
.ccalculator .btn-container.disabled
{opacity: 0.5; pointer-events:none; }

.tire-size-corrispondence-result  .btn-size {background-color:#002664 !important; color:white;}
.tire-size-corrispondence-result  .btn-size:hover {color:#cccccc;}
.size-params-container.accordion{padding-left:0;padding-right:0; margin-top:10px; margin-bottom: 20px;}

.tire-size-corrispondence-result .btn-back {margin-top:5px;}




.mechanical-lead-calculator {padding-bottom:40px;}
.mechanical-lead-calculator .front-titolo,
.mechanical-lead-calculator .rear-titolo,
.mechanical-lead-calculator .axle-titolo

{width:100%; background-color:#E6E6E6;text-align: center; padding: 5px;  margin-bottom:10px; }
.mechanical-lead-calculator .front-titolo,
.mechanical-lead-calculator .axle-titolo
{margin-top:20px;}
.mechanical-lead-calculator .axle-sottotitolo {font-size: 14px; }
.mechanical-lead-calculator .btn-container {transition: opacity 1s;}
.mechanical-lead-calculator .btn-container.disabled {opacity: 0.5; pointer-events:none;}
.mechanical-lead-calculator .btn-container.loading {animation: loadingFadeInOut 1s linear; animation-iteration-count: infinite;  pointer-events:none;}
.mechanical-lead-calculator .error {color:red; }
.mechanical-lead-calculator .result {padding:.375rem .75rem; border:1px solid #ced4da; margin-top:0px; margin-bottom:20px; }
.mechanical-lead-calculator .result-titolo {padding-top:.75rem; }

@keyframes loadingFadeInOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.apps .lista-grigia {height:auto !important; font-size:20px !important; text-align: center;  display:flex;
    justify-content: center; background-color: white; cursor: default; width:100%;}
.apps  .limita-icona {   margin:5px 0; display: inline-block; width:80%; }
@media (max-width: 576px)
{
    .apps .lista-grigia {border-bottom: #eeeeee 3px solid;}
}


.filter.active {z-index:5; position:fixed; left:0; width:100%; top:0; height: 100%; background-color: white; padding:15px; }
.filter.active .filter-titolo{font-weight: bold;     flex: 0 0 100% !important; max-width: 100% !important; margin-top:30px; margin-bottom: 15px;}
.filter.active .filter-container-select { flex: 0 0 100% !important; max-width: 100% !important; }
.filter-titolo {padding-top:7px; }

.mb-15 {margin-bottom:15px; }

.product .titolo {
   margin-bottom: 20px;
   text-transform: none;
   font-size: 28px;
}


.ccalculator-result .size-filter {border: 2px solid #002664; padding:15px 15px 10px 15px;  }
.ccalculator-result .size-filter .filter .filter-titolo {color:#002664; font-weight: bold;}
.ccalculator-result .ccalculator-result-container {color:#002664;  margin-top: 20px; margin-bottom: 40px; background-color: #002664; color:white; padding-top:15px; padding-bottom: 15px; }
.ccalculator-result-box {padding:0 15px;}
.ccalculator-pressure {font-weight: normal;}
.ccalculator-pressure.result {font-weight: bold;}